import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import TeamBannerData from '../Data/TeamBannerData.json'

const AppTeamBannerWappper = styled.div`
  max-width: 1920px;
  margin: auto;
  .TeamBannerSection {
    padding: 80px 10px 50px 96px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 50px 15px;
    }
  }
  .teamBannerContent {
    padding-top: 5rem;
    @media (max-width: 1090px) {
      padding-top: unset;
    }
  }

  .TeamBannerImageMobile {
    display: none;
    @media (max-width: 1090px) {
      display: flex;
      height: auto;
      width: 100%;
    }
  }
  .TeamBannerImageBIG {
    height: auto;
    width: 100%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .teamBannerHeading {
    text-align: left;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    @media (max-width: 1090px) {
      text-align: center;
    }
  }
  .teamBannerPara {
    text-align: left;
    max-width: 850px;
    @media (max-width: 1090px) {
      padding-top: 3rem;
      margin: auto;
    }
  }
`

export const AppTeamBanner = () => {
  return (
    <AppTeamBannerWappper>
      {TeamBannerData.map((data, index) => {
        return (
          <div className='TeamBannerSection' key={index}>
            <div className='teamBannerContent'>
              <ReUseHOneTag Heading={data.title} HeadingStyle='teamBannerHeading' />
              <ReuseImgTag ImageStyle='TeamBannerImageMobile' ImagePath={data.ImagePath} AltName={data.AltName} />
              <ReUsePtag para={data.para} paraStyle='teamBannerPara' />
            </div>
            <div>
              <ReuseImgTag ImageStyle='TeamBannerImageBIG' ImagePath={data.ImagePath} AltName={data.AltName} />
            </div>
          </div>
        )
      })}
    </AppTeamBannerWappper>
  )
}
