import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import { AppTeamBanner } from '../../components/AppTeamBanner'
// import { AppTeamMember } from '../../components/AppTeamMember'
import { AppTeamMemberSelect } from '../../components/AppTeamMemberSelect'
import { AppGetTeam } from '../../components/AppGetTeam'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
var scroll = Scroll.animateScroll

const scrollData = {
  TotalSolution: 700,
  Security: 1000,
  ExceptionalService: 700,
  TailoredFit: 700,
  Experience: 1000,
  Quality: 1000,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const team = (props: any) => {
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])
  return (
    <Layout>
      <RDHelmet subtitle={'Raindrop Team'} />
      <AppTeamBanner />
      <AppGetTeam />
      <AppTeamMemberSelect />
      {/* <AppTeamMember /> */}
    </Layout>
  )
}

export default team
