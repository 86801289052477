import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppGetTeamWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SectionHeading {
    max-width: 400px;
    margin: auto;
    text-align: center;
    /* border-bottom: 2px solid #047cc2; */
    @media (max-width: 1090px) {
      border-bottom: unset;
    }
  }
  .TeamUpperPara {
    max-width: 1050px;
    text-align: left;
    margin: auto;
    display: flex;
    padding-top: 3rem;
    padding-bottom: 2rem;
    @media (max-width: 780px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`

export const AppGetTeam = () => {
  return (
    <AppGetTeamWapper>
      <ReUseHTwotag Heading='Get To Know The Team' HeadingStyle='SectionHeading' />
      <ReUsePtag
        para='At Raindrop, we believe in supporting our employees, not managing them. Meet the team who is supporting all of our employees to help them ensure customer success, focus on results and strive for excellence.'
        paraStyle='TeamUpperPara'
      />
    </AppGetTeamWapper>
  )
}
