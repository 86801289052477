import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Switch, SwitchPlans } from './Switch'
import { AppAdvisorTeam } from './AppAdvisorTeam'
import { AppTeamMember } from './AppTeamMember'

const AppTeamMemberSelectWrapper = styled.section`
  text-align: center;
`

export const AppTeamMemberSelect = () => {
  const [teamSelected, setteamSelected] = useState(false)

  useEffect(() => {}, [teamSelected])
  return (
    <AppTeamMemberSelectWrapper>
      <div
        style={{
          marginTop: 50,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <SwitchPlans SwitchOne="Raindrop Team" SwitchTwo="Advisor Team" onChange={setteamSelected} />
      </div>
      {!teamSelected && (
        <div>
          <AppTeamMember />
        </div>
      )}
      {teamSelected && (
        <div>
          <AppAdvisorTeam />
        </div>
      )}
    </AppTeamMemberSelectWrapper>
  )
}
