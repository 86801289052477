import React from 'react'
import { FaLinkedinIn } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/vijaycaveripakkam/',
    TeamMemberName: 'Jyoti Bansal',
    DECHeading: 'Advisor',
    DECPara: 'Technology industry entrepreneur and leader from startup through acquisition.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/JyotiBansalAdvisorPage.png',
    AltTag: 'VijayImage',
  },
  // {
  //   id: '2',
  //   icon: <FaLinkedinIn color='#007ab9' size='45' />,
  //   ProfileUrl: 'https://www.linkedin.com/in/wardkarson/',
  //   TeamMemberName: 'Ralph Laura',
  //   DECHeading: 'Advisor',
  //   DECPara: 'Accomplished thought leader and CIO providing support, guidance and strategy to businesses and Boards.',
  //   ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RalphAdvisorPage.png',
  //   AltTag: 'WordImage',
  // },
  {
    id: '3',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/john-moore-528223/',
    TeamMemberName: 'Paul Patterson',
    DECHeading: 'Advisor',
    DECPara: 'Revenue function industry executive driving company growth and customer strategy. ',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/PaulAdvisorpage.png',
    AltTag: 'JohnImage',
  },
  {
    id: '4',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/john-moore-528223/',
    TeamMemberName: 'Gary Bischoping',
    DECHeading: 'Advisor',
    DECPara:
      'Finance leader experienced in private equity, investor relations, international business, consulting, treasury, and technology. ',
    ImagePath: '/img/gary-e-bischoping-jr.png',
    AltTag: 'JohnImage',
  },
]
