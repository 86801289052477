import React, { useState } from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { AppBioModel } from './AppBioModel'
import TeamMemberDetailsData from '../Data/TeamMemberDetailsData'

const AppTeamMemberWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .TeamMemberMainsection {
    padding: 0px 96px 50px 96px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .SectionHeading {
    max-width: 400px;
    margin: auto;
    text-align: center;
    /* border-bottom: 2px solid #047cc2; */
    @media (max-width: 1090px) {
      border-bottom: unset;
    }
  }
  .TeamUpperPara {
    max-width: 1050px;
    text-align: left;
    margin: auto;
    display: flex;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .firstCardSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    text-align: center;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .TeamCardSection {
    width: 400px;
    border-radius: 12px;
    box-shadow: 0px 3px 7px 0 rgba(160, 161, 163, 0.88);
    background-color: #ffffff;
    border: 1px solid #34a1d5;
    margin: 3rem 0rem 3rem 0rem;

    @media (max-width: 1090px) {
      margin: 1rem 0rem 1rem 0rem;
    }
  }
  .TeamImageBIG {
    width: 345px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin: auto;
    position: absolute;
    left: 1%;
  }
  .TeamImageBIG:hover {
    background-color: #1f1f1f;
  }
  .RohitaTeamImageBIG {
    width: 240px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .NameHeading {
    text-align: center;
  }
  .DESHeading {
    font-size: 24px;
    color: #34a1d5;
    text-align: center;
  }
  .DecParaStyle {
    font-size: 18px;
    text-align: center;
    padding: 15px;
  }
  button:focus {
    outline: none;
  }
  .BioButton {
    background-color: transparent;
    border: none;
    display: flex;
    margin: auto;
  }
  .ButtonHover {
    margin: auto;
    position: absolute;
    width: 160px;
    left: 100px;
    top: 110px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    color: #fff;
    border: solid 1px #fff;
    border-radius: 18px;
    padding: 12px;
    z-index: 0;
  }
  .MediaIconSection {
    display: flex;
    margin: auto;
    justify-content: center;
    padding: 1rem 0rem 1rem 0rem;
  }
  .TeamImageSection {
    position: relative;
    width: 360px;
    height: 310px;
    margin: auto;
    border-radius: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .TeamImageSection:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 18px;
    cursor: pointer;
  }
  .TeamImageSection:hover .ButtonHover {
    opacity: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: 0.5s ease;
  }
`

export const AppTeamMember = () => {
  const [showBio, setshowBio] = useState(false)
  const [pIndex, setIndex] = useState(-1)

  const setBio = (index: any) => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
    setIndex(index)
    setshowBio(true)
  }

  return (
    <AppTeamMemberWapper>
      <div className="TeamMemberMainsection">
        <div className="firstCardSection">
          {TeamMemberDetailsData.map((data, index) => {
            return (
              <div className="TeamCardSection" key={index} id={data.id}>
                <div className="TeamImageSection">
                  <button className="BioButton" onClick={() => setBio(index)}>
                    <ReuseImgTag ImageStyle="TeamImageBIG" ImagePath={data.ImagePath} AltName={data.AltTag} />
                    {/* <img src={data.ImagePath} alt={data.AltTag} className='TeamImageBIG' /> */}
                    <div className="overlay"></div>
                    <p className="ButtonHover">Read Full Bio</p>
                  </button>
                  <AppBioModel idx={pIndex} showBio={showBio} setshowBio={setshowBio} />
                </div>
                <ReUseHTwotag Heading={data.TeamMemberName} HeadingStyle="NameHeading" />
                <ReUseHTwotag Heading={data.DECHeading} HeadingStyle="DESHeading" />
                <ReUsePtag para={data.DECPara} paraStyle="DecParaStyle" />
                {/* <div className='MediaIconSection'>
                  <a href={data.ProfileUrl} target='_blank' rel='noopener noreferrer'>
                    {data.icon}
                  </a>
                </div> */}
              </div>
            )
          })}
        </div>
      </div>
    </AppTeamMemberWapper>
  )
}
