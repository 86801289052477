import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { GetCookie, setHubspotPageView } from './LinkHubspot'

const ContainerDiv = styled.div`
  margin-bottom: 16px;
  min-width: 370px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #dfdfdf;
 //background: ${(props) => (props.isOn ? 'linear-gradient(to left, #b91d73, #f953c6)' : 'rgba(255, 255, 255, 0.4)')};
  display: flex;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  border-radius: 50px;
  padding: 0px;
  cursor: pointer;

  .text {
    margin: 0;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 20px !important;
    width: 100% !important;
  }
  .textHalfWidth {
    margin: 0;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 20px !important;
    width: 50% !important;
  }
  .textBox {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 50px !important;
    width: 100% !important;
  }

  .textFlexBox {
    display: flex;
    margin: 0;
    height: 20px !important;
    width: 100% !important;
  }
`

const ContainerDivPlans = styled.div`
  margin-bottom: 16px;
  min-width: 370px;
  width: 100%;
  max-width: 600px;
  height: 50px;
  border-radius: 25px;
  background: #dfdfdf;
 //background: ${(props) => (props.isOn ? 'linear-gradient(to left, #b91d73, #f953c6)' : 'rgba(255, 255, 255, 0.4)')};
  display: flex;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  border-radius: 50px;
  padding: 0px;
  cursor: pointer;

  .text {
    margin: 0;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 50px !important;
    width: 100% !important;
  }
  .textHalfWidth {
    margin: 0;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 20px !important;
    width: 50% !important;
  }
  .textBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative !important;
    border: none !important;
    background: none !important;
    white-space: nowrap;
    height: 50px !important;
    width: 100% !important;
  }

  .textFlexBox {
    display: flex;
    margin: 0;
    height: 20px !important;
    width: 100% !important;
  }
`

const HandleCircle = styled(motion.div)`
  width: 48%;
  max-width: 300px;
  height: 50px;
  background-color: white;
  border-radius: 25px;
  position: absolute;
  border: 1px solid #34a1d5;
`

export const Switch = (props: any) => {
  const [isOn, setOn] = useState(true)

  const toggle = () => {
    setOn(!isOn)
    props.onChange(!isOn)
  }

  return (
    <ContainerDiv isOn={isOn} onClick={toggle}>
      <HandleCircle
        whileHover={{ scale: 1.2 }}
        layout
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 25,
        }}
      />

      <div className='textBox'>
        <div className='text' style={{ fontSize: 14 }}>
          Pay Monthly
        </div>

        <div className='text' style={{ fontSize: 12 }}>
          Commit annually
        </div>
      </div>
      <div className='textBox'>
        <div className='textFlexBox'>
          <p className='textHalfWidth' style={{ fontSize: 14, textAlign: 'right' }}>
            Pay Upfront
          </p>
          <p
            className='textHalfWidth'
            style={{ fontSize: 14, textAlign: 'left', paddingLeft: 6, color: '#34a1d5', fontWeight: 'bold' }}>
            SAVE 10%
          </p>
        </div>

        <div className='text' style={{ fontSize: 12 }}>
          Commit annually
        </div>
      </div>
    </ContainerDiv>
  )
}

export const SwitchPlans = (props: any) => {
  const [isOn, setOn] = useState(false)

  const toggle = () => {
    if (isOn) {
      setHubspotPageView('/pricing#paidplans')
    } else {
      setHubspotPageView('/pricing#freeplan')
    }

    setOn(!isOn)
    props.onChange(!isOn)
  }

  return (
    <ContainerDivPlans isOn={isOn} onClick={toggle}>
      <HandleCircle
        whileHover={{ scale: 1.2 }}
        layout
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 25,
        }}
      />
      <div className='textBox'>
        <div className='textFlexBox'>
          <div className='text' style={{ fontSize: 20, color: '#34a1d5' }}>
            {props.SwitchOne}
          </div>
        </div>
      </div>
      <div className='textBox'>
        <div className='textFlexBox'>
          <div className='text' style={{ fontSize: 20, color: '#34a1d5' }}>
            {props.SwitchTwo}
          </div>
        </div>
      </div>
    </ContainerDivPlans>
  )
}
